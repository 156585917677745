let Vue = require('vue');

Vue.component('questionAstro', {
	data: function(){
		return{
			selected:false
		}
	},
	methods: {
		select(value){
			this.selected = true;
			this.$emit('update-result', value)
		}
	}
});
