var Vue = require('vue');

Vue.component('videoelement', {
	data: function(){
		return{
			overlay: true,
		}
	},
	props:['src'],
	methods: {
		playVideo:function(){
			this.overlay = false;
		}
	}
});
