let stringSimilarity = require("string-similarity");

let Vue = require('vue');

Vue.component('questionTimer', {
	props:['answers','url','quiz_id','duration'],
	data(){
		return{
			timer:false,
			total_time:0,
			time_left:0,
			answer:'',
			message:'',
			information:'',
			information_mode:'normal',
			attempts:0,
			valid_attempts:[]
		}
	},
	methods:{
		giveUp(){
			this.stopTimer();
		},
		replay(){
			this.time_left = this.total_time;
			this.answers = this.valid_attempts.concat(this.answers);
			this.valid_attempts = [];
			this.startTimer();
		},
		checkAnswer(e){

			let answer = e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/`/g, " ").replace(/'/g, " ");

			if( !answer.length || !this.answers.length )
				return;

			this.attempts++;

			for(let i=0; i<this.answers.length; i++){

				let titles = this.answers[i]['title'].split('\n');

				for(let j=0; j<titles.length; j++){

					let title = titles[j];
					let compare = title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/`/g, " ").replace(/'/g, " ");
					let similarity = stringSimilarity.compareTwoStrings(compare, answer);

					if((this.answers[i]['exact'] && similarity === 1) || (!this.answers[i]['exact'] && similarity >= 0.8)){

						console.log(compare+' vs '+ answer + ' : ' +similarity)

						this.valid_attempts.push(title)
						this.answers.splice(i, 1);

						this.displayValidAnswer();

						if( this.answers.length === 0)
							this.stopTimer();

						return;
					}
				}
			}

			this.displayWrongAnswer()
		},
		parseTime(timer){

			let minutes, seconds;

			minutes = parseInt(timer / 60, 10);
			seconds = parseInt(timer % 60, 10);

			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;

			return minutes + ":" + seconds;
		},
		parseMinutes(time){

			let minutes;
			minutes = parseInt(time / 60, 10);
			return minutes + " minute" + (minutes>1?'s':'');
		},
		tick(){
			if( this.time_left-- <= 1 )
				this.stopTimer();
		},
		startTimer(){

			if( !this.timer )
				this.timer = setInterval(this.tick, 1000);
		},
		stopTimer(){
			clearInterval(this.timer);
			this.timer = false;
			this.time_left = 0;
		},
		resetInformation(){
			this.information = false;
			this.information_mode = 'normal';
		},
		displayValidAnswer(){
			this.information = this.answers.length ? 'Bien joué !' : 'Wooow !';
			this.information_mode = 'correct';
			setTimeout(()=>{
				this.answer = '';
				this.resetInformation();
			}, 1000);
		},
		displayWrongAnswer(){
			this.information = 'Nope !';
			this.information_mode = 'wrong';
			setTimeout(this.resetInformation, 1000);
		}
},
	mounted(){
		this.time_left = this.total_time = this.duration ? this.duration*60 : this.answers.length*15;
	}
});
