let Vue = require('vue');

Vue.component('questionPersonality', {
	data: function(){
		return{
			selected:false
		}
	},
	methods: {
		select(value,e){
			e.target.closest('.m-question__answer').classList.add('m-question__answer--selected')
			this.selected = true;

			let next = this.$el.nextElementSibling;

			let nextQuestion = next && next.classList.contains('cgIncontentSlot') ? next.nextElementSibling : next

			if(nextQuestion && nextQuestion.classList.contains('m-question')){
				let top = nextQuestion.getBoundingClientRect().top + window.scrollY;

				if(top)
					window.scrollTo({ top: top-328, left: 0, behavior: 'smooth' })
			}


			this.$emit('update-result', value)
		}
	}
});
