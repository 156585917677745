let stringSimilarity = require("string-similarity");

let Vue = require('vue');

Vue.component('questionTrivia', {
	props:['answer','total'],
	data: function(){
		return{
			isAnswered: false,
			isCorrect: false,
			free: '',
			count: 0,
			countCorrect: 0,
			credit: 1
		}
	},
	methods: {
		removeAnswer(value){

			this.count--;

			if(value)
				this.countCorrect--

			this.isAnswered = this.count === this.total
			this.isCorrect = this.countCorrect === this.total;

		},
		countAnswers(valid){

			if( this.isAnswered )
				return;

			this.count++;

			if(valid)
				this.countCorrect++

			this.isAnswered = this.count === this.total
			this.isCorrect = this.countCorrect === this.total;

			if(this.isAnswered){

				this.$emit('update-result', (this.isCorrect ? this.credit : 0))

				let top = this.$refs.result.getBoundingClientRect().top + window.scrollY;

				if(top)
					window.scrollTo({ top: top-298, left: 0, behavior: 'smooth' })
			}
		},
		checkFreeAnswer:function(e){

			if( this.isAnswered || !e.target.value.length )
				return;

			let answer = e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/`/g, " ").replace(/'/g, " ");

			for(let i=0; i<this.answer.length; i++){

				let compare = this.answer[i].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/`/g, " ").replace(/'/g, " ");

				let exact = compare.substr(0,1) === '!';

				if( exact )
					compare = compare.substr(1);

				let similarity = stringSimilarity.compareTwoStrings(answer, compare);

				console.log(compare+' vs '+ answer + ' : ' +similarity)

				if((exact && similarity === 1) || (!exact && similarity >= 0.8)){

					this.isCorrect= true;
					break;
				}
			}

			this.isAnswered = true
			let top = this.$refs.result.getBoundingClientRect().top + window.scrollY;

			if(top)
				window.scrollTo({ top: top-298, left: 0, behavior: 'smooth' })

			this.$emit('update-result', (this.isCorrect ? this.credit : 0))
		}
	}
});
