var Vue = require('vue');

Vue.component('quiz', {
	data: function(){
		return{
			score: 0,
			personality: {},
			finished: false,
			answered: 0
		}
	},
	props:['questions','quiz_id','url','type'],
	methods: {
		calculateResult(point){
			this.score += point
			this.answered++

			if(this.questions === this.answered)
				this.finished = true
		},
		setResult(point){
			this.score = point
			this.finished = true

			let top = this.$refs.result.getBoundingClientRect().top + window.scrollY;

			if(top)
				window.scrollTo({ top: top-356, left: 0, behavior: 'smooth' })
		},
		updateResult(value){

			for (let i=0; i<value.length; i++){

				if( typeof this.personality[value[i].title] == "undefined" )
					this.personality[value[i].title] = 0;

				this.personality[value[i].title] += parseInt(value[i].points)
			}

			this.answered++

			if(this.questions === this.answered){

				let max = 0;
				let score = false;

				for(const index in this.personality){

					if( this.personality[index] > max){

						max = this.personality[index];
						score = index;
					}
				}

				this.score = score;
				this.finished = true;
				let top = this.$refs.result.getBoundingClientRect().top + window.scrollY;

				if(top)
					window.scrollTo({ top: top-376, left: 0, behavior: 'smooth' })
			}
		}
	}
});
