var Vue = require('vue');

Vue.component('share', {
	data: function(){
		return{
			copied: false
		}
	},
	props:['link','title','text'],
	methods: {
		share(){

			if (navigator.share) {
				navigator.share({
					title: this.title,
					text: this.text,
					url: this.link
				})
						.then(() => console.log('Successful share'))
						.catch((error) => console.log('Error sharing', error));
			} else {

				let el = document.createElement("textarea");
				el.value = this.link;
				el.setAttribute('readonly', '');
				el.style.position = 'absolute';
				el.style.left = '-9999px';
				document.body.appendChild(el);
				el.select();
				document.execCommand('copy');
				document.body.removeChild(el);

				const self= this;
				self.copied = true;

				setTimeout(function(){
					self.copied = false;
				},1500)
			}

		}
	}
});