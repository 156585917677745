var Vue = require('vue');

Vue.directive('submenu', {
    // Quand l'élément lié est inséré dans le DOM...
    inserted: function (el) {

        let $elements = el.getElementsByClassName('current-post-parent');
        if( $elements.length )
            $elements[$elements.length-1].classList.add('last-of-type')
    }
})