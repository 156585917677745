let Vue = require('vue');

Vue.component('answer', {
	props:['isRight'],
	data: function(){
		return{
			isCorrect: false,
			isSelected: false
		}
	},
	methods:{
		checkAnswer:function(e){
			if(e.target.tagName !== 'A'){
				let oldCorrect = this.isCorrect

				if(this.isRight === 1 || this.isRight === '1')
					this.isCorrect= true

				if(!this.isSelected)
					this.$emit('selected-answer',this.isCorrect)
				else
					this.$emit('deselected-answer',oldCorrect)

				this.isSelected = !this.isSelected;
			}
		}
	}
});
